module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":50,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../plugins/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[],"background":"#232129"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Inna Grygoraschenko","short_name":"IGRIG","lang":"en","description":"Adventurer, photographer, inspirer, extreme sportswoman","start_url":"/","background_color":"#2e3246","theme_color":"#3498DB","display":"standalone","icon":"/home/runner/work/igrig/igrig/src/assets/images/icon.png","localize":[{"start_url":"/uk//","lang":"uk","name":"Інна Григоращенко","short_name":"ІГРІГ","description":"Авантюрист, фотограф, натхненниця, екстремальна спортсменка"},{"start_url":"/ru//","lang":"ru","name":"Инна Григоращенко","short_name":"ИГРИГ","description":"Авантюрист, фотограф, вдохновительница, экстремальная спортсменка"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ba216312cfea9f5a9bb8c333aabda5d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
